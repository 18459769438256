<template>
  <div class="pageContol classRecord">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="$route.query.stuD=='detail'">
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">班级审核</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">点名设置预览</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toOrganizationList" class="cur-a">机构列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">开班记录</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级档案</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs v-model="activeName" @tab-click="tabsChange" v-if="$route.query.stuD!='detail'">
          <el-tab-pane label="基本信息" name="first">
            <div class="ovy-a">
              <h3 style="padding-bottom:20px;">基本信息</h3>
              <el-form ref="ruleForm" label-width="150px" class="formstyle">
                <el-form-item label="班级名称:" class="form-inline">
                  <p>{{detail.projectName}}</p>
                </el-form-item>
                <el-form-item label="班级编码:" class="form-inline">
                  <p>{{detail.projectCode}}</p>
                </el-form-item>
                <el-form-item label="行政区划:" class="form-inline">
                  <p>{{ detail.areaNamePath||"无"}}</p>
                </el-form-item>
                <el-form-item label="有效日期:" class="form-inline">
                  <p
                    v-if="detail.startDate&&detail.endDate"
                  >{{detail.startDate|momentDate}}至{{detail.endDate|momentDate}}</p>
                  <p v-else>无</p>
                </el-form-item>
                <el-form-item label="报名截止时间:" class="form-inline">
                  <p v-if="detail.signEndDate">{{detail.signEndDate|momentDate}}</p>
                  <p v-else>无</p>
                </el-form-item>
                <el-form-item label="负责人:" class="form-inline">
                  <p>{{detail.projectUser||""}}</p>
                </el-form-item>
                <el-form-item label="负责人电话:" class="form-inline">
                  <p>{{detail.projectUserPhone||""}}</p>
                </el-form-item>
                <el-form-item label="培训类型:" class="form-inline">
                  <p>{{detail.Trainingtype||"无"}}</p>
                </el-form-item>
                <el-form-item label="培训人数:" class="form-inline">
                  <p>{{detail.projectPeople||"无"}}</p>
                </el-form-item>
                <el-form-item label="培训地点:" class="form-inline">
                  <p>{{detail.projectAddress||"无"}}</p>
                </el-form-item>
                <el-form-item label="期次:" class="form-inline">
                  <p>{{detail.projectPc||"无"}}</p>
                </el-form-item>
                <el-form-item label="考试机构:" class="form-inline">
                  <p>{{detail.projectPaperComp||"无"}}</p>
                </el-form-item>
                <el-form-item label="考试时间:" class="form-inline">
                  <p v-if="detail.projectPaperDate">{{detail.projectPaperDate|momentDate}}</p>
                  <p v-else>无</p>
                </el-form-item>
                <el-form-item label="人群类型:" class="form-inline" v-show="detail.projectCrowd">
                  <p v-if="detail.projectCrowd">
                    {{
                    $setDictionary("CROWDTYPE", detail.projectCrowd)
                    }}
                  </p>
                </el-form-item>
                <el-form-item label="申请表图片:" class="form-inline">
                  <div class="img-el-upload" style="width:256px;" v-if="detail.applicationFormUrl">
                    <el-image
                      :src="detail.applicationFormUrl|| require('@/assets/develop.png')"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div v-else>无</div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="机构点名" name="second">
            <organizationRollCall :rollType="rollType" />
          </el-tab-pane>
          <el-tab-pane label="监管点名" name="three">
            <organizationRollCall :rollType="rollType" :stus="stus"/>
          </el-tab-pane>
        </el-tabs>
        <organizationRollCall :rollType="rollType" :stus="stus" v-else />
        <div class="bottom-btn" style="text-align: center;">
          <el-button @click="saveYes" class="bgc-bv" v-if="$route.query.stuD=='detail'">  确定</el-button>
          <el-button @click="$router.back()" class="bgc-bv">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import organizationRollCall from "@/views/classListCom/classDetailsFile/organizationRollCall.vue";
export default {
  name: "sjzRegulator/classRecord",
  components: {
    organizationRollCall
  },
  data() {
    return {
      activeName: "first",
      detail: {},
      rollType: "",
      stus:this.$route.query.stus,
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.stuD == "detail") {
        this.rollType = "20";
      } else {
        this.getDetail();
      }
    },
    saveYes() {
      this.$post("/run/project/modifyAuditPassState", {
        resourceId: this.$route.query.projectId,
        auditState: "30"
      }).then(re => {
        if (re.status == 0) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
         
          this.$router.push({
            path: "/web/SupervisionEnd/sjzRegulator/classReview"
          });
         
        }
      });
    },
    toOrganizationList() {
      this.$router.replace({
        path: "/web/SupervisionEnd/sjzRegulator/organizationList"
      });
    },
    tabsChange(e) {
      this.activeName = e.name;
      if (e.name == "second") {
        this.rollType = "10";
      }
      if (e.name == "three") {
         this.rollType = "20";
         this.stus = 'details'
      }
    },
    getDetail() {
      this.$post("/biz/project/query", {
        projectId: this.$route.query.projectId
      }).then(re => {
        this.detail = re.data;
        let name1 = "";
        let name2 = "";
        let name3 = "";
        let name4 = "";
        let name5 = "";
        if (re.data.codeBo.trainTypeNamePath) {
          name1 = re.data.codeBo.trainTypeNamePath;
        }
        if (re.data.codeBo.postName) {
          name2 = "/" + re.data.codeBo.postName;
        }
        if (re.data.codeBo.industryNamePath) {
          name3 = "/" + re.data.codeBo.industryNamePath;
        }
        if (re.data.codeBo.occupationNamePath) {
          name4 = "/" + re.data.codeBo.occupationNamePath;
        }
        if (re.data.codeBo.trainLevelName) {
          name5 = "/" + re.data.codeBo.trainLevelName;
        }
        let name = name1 + name2 + name3 + name4 + name5;
        this.detail.Trainingtype = name.replace(/,/g, "/");
      });
    }
  }
};
</script>
<style lang="less">
.classRecord {
  .formstyle {
    overflow: hidden;
    .form-inline {
      width: 50%;
      float: left;
      margin-bottom: 0.625rem;
    }
  }
}
</style>
